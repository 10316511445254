import LayoutAdmin from '@/layouts/LayoutAdmin'
import myTable from './table'

export default {
  data() {
    return {
      kata_kunci: null,
      pilih_cif: null,
      search: '',
      loadingTb: false,
      listCif: [],
      tbData: [],
      getData: [],
      setId: null,
      listRegister: [],
      listProject: [],
      listEvent: [],
      list_usersTypes: [],
      list_userGroup: [],
      listBalai:[],
      porsi:0,
      isShow:false,
      tableDetail:[],
      setDefault:null,
      projectId:null,
      satkerId:null

    }
  },
  created() {
    this.$emit(`update:layout`, LayoutAdmin);
  },
  mounted() {
    this.getTable()
    this.G_GetRef('balai').then(data => {
      this.listBalai = data;
    });

    this.G_GetRef('satker/type').then(data => {
      this.listType = data;
    });

    
   
  },
  components: {
    myTable
  },
  methods: {
    getTable() {
      this.isShow = true
      this.loadingTb = true
      let filter = ''
      // if (this.$refs.filter_event.getValue()) {
      //   filter = '/'+this.$refs.filter_event.getValue()
      // }

      this.G_GetAny('satker'+filter).then(res => {
        console.log('satker',res);
        this.loadingTb = false
        this.tbData = res.data.content
      })
    },
    
    edit(id) {
     
      console.log(id);
      this.$refs.modal.open("Edit Data KPPN per Satker", 600)
      this.setId = id

      this.G_GetAny('satker/'+id).then(res => {
        console.log('satker edit',res);
        this.getData = res.data

        this.$nextTick(() => {
          // this.$refs.satkerId.setValue(res.satkerId)
          // this.$refs.satkerNm.setValue(res.satkerNm)
          this.$refs.kdbalai.setValue(res.data.kdbalai)
          // this.$refs.typ.setValue(res.typ)
          // this.$refs.kdkppn.setValue(res.kdkppn)

        });

      })

       
    },
    add() {
      if (this.$refs.form) {
        this.$refs.form.reset()
        this.$refs.form.resetValidation()
      }
      this.setId = null
      this.getData = []
      this.$refs.modal.open("Tambah Data KPPN per Satker", 600)

    },
    store() {
      let myForm = document.getElementById('formData');
      let formData = new FormData(myForm);
      formData.append('kdbalai',this.$refs.kdbalai.getValue())
      formData.append('typ',this.$refs.typ.getValue())
     


      if (this.$refs.form.validate()) {
          this.$refs.modal.loading()

          if (this.setId) {
            this.G_PutAny('satker', formData).then(res => {
              if (!res.status) {
                this.getTable();
                this.$snotify.success('Berhasil Mengubah Data');
                this.$refs.modal.close()
              }else{
                this.$snotify.warning('Terjadi Kesalahan');
              }
            })
            .finally(() => {
              this.$refs.modal.loadingEnd()
              
            });
          }else{
            console.log('add');
            this.G_PostAny('satker', formData).then(res => {
              if (!res.status) {
                this.getTable();
                this.$snotify.success('Berhasil Menyimpan Data');
                this.$refs.modal.close()
              }else{
                this.$snotify.warning('Terjadi Kesalahan');
              }
            })
            .finally(() => {
              this.$refs.modal.loadingEnd()
              
            });
          }

         
      }
      else {
        this.$snotify.info('Lengkapi Data Terlebih Dahulu');
      }
    },
    async hapus(id) {
      let judul = "Hapus Data"
      let subJudul = "Yakin Akan Hapus Data Ini?"

      if (await this.$refs.confirm.open(judul,subJudul) ) {
        
          this.$refs.confirm.loading()
          this.G_DeleteAny('satker/'+id).then(res => {
              console.log(res);
              if (res) {
                  this.$refs.confirm.close()
                  this.getTable();
                  this.$snotify.success('Berhasil Hapus Data');
              }else{
                this.$snotify.warning(res.message);
              }
          })
          .finally(() => {
              this.$refs.confirm.loadingEnd()
          }); 
      }

    },
    detail(projectId){

      // this.projectId = projectId 
      // this.G_GetAny(`/porsiLoan/projek/${this.$refs.filter_event.getValue()}/${projectId}`).then(res => {
      //   console.log(res);
      //   this.tableDetail = res.data.content
      //   this.$refs.modalDetail.open("Detail Data KPPN per Satker", 1000)
      
      // })
    },

  
  }
}